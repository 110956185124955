<template>
  <div>
    <p class="text-sm grey--text text--darken-2 px-5 pt-6">
      {{ $t('MON COMPTE') }}
    </p>
    <router-link
      to="/profile"
      class="dashboard-nav-link px-4"
    >
      <div class="d-flex align-center">
        <img
          v-svg-inline
          class="icon mr-2"
          src="@/assets/images/icons/user.svg"
          alt=""
        >
        <span>{{ $t("Mon profil") }}</span>
      </div>
      <!--      <p class="mb-0">3</p>-->
    </router-link>
    <!--    <router-link to="/edit-profile" class="dashboard-nav-link px-4">
      <div class="d-flex align-center">
        <img v-svg-inline class="icon mr-2" src="@/assets/images/icons/edit.svg" alt="" />
        <span>{{ $t("Modifier mon profil")}}</span>
      </div>
      <p class="mb-0">3</p>
    </router-link>-->
    <router-link
      to="/notifications"
      class="dashboard-nav-link px-4"
    >
      <div class="d-flex align-center">
        <img
          v-svg-inline
          class="icon mr-2"
          src="@/assets/images/icons/comment.svg"
          alt=""
        >
        <span>{{ $t("Notifications") }}</span>
      </div>
      <p class="mb-0">
        <v-chip
          v-if="$store.getters.unreadNotifications.length"
          small
          color="primary"
        >
          {{ $store.getters.unreadNotifications.length || '' }}
        </v-chip>
      </p>
    </router-link>
    <!--    <router-link to="/list-address" class="dashboard-nav-link px-4">
      <div class="d-flex align-center">
        <img v-svg-inline class="icon mr-2" src="@/assets/images/icons/pin.svg" alt="" />
        <span>Addresses</span>
      </div>
    </router-link>-->

    <!--    <router-link  to="/all-tickets" class="dashboard-nav-link px-4">
      <div class="d-flex align-center">
        <img v-svg-inline class="icon mr-2" src="@/assets/images/icons/customer-service.svg" alt="" />
        <span>{{ $t("Support Tickets") }}</span>
      </div>
      <p class="mb-0">1</p>
    </router-link >-->
    <p class="text-sm grey--text text--darken-2 px-5 pt-6">
      {{ $t("ACHETEUR") }}
    </p>
    <router-link
      to="/orders"
      class="dashboard-nav-link px-4"
    >
      <div class="d-flex align-center">
        <img
          v-svg-inline
          class="icon mr-2"
          src="@/assets/images/icons/bag.svg"
          alt=""
        >
        <span>{{ $t("Commandes") }}</span>
      </div>
      <!--          <p class="mb-0">5</p>-->
    </router-link>

    <!--        <router-link to="/wishlist" class="dashboard-nav-link px-4">-->
    <router-link
      to="/user/packages"
      class="dashboard-nav-link px-4"
    >
      <div class="d-flex align-center">
        <img
          v-svg-inline
          class="icon mr-2"
          src="@/assets/images/icons/delivery-truck.svg"
          alt=""
        >
        <span>{{ $t("Colis à Recevoir") }}</span>
      </div>
      <!--            <p class="mb-0">19</p>-->
    </router-link>

    <p
      v-if="user && (user.is_seller || user.is_pro_seller)"
      class="text-sm grey--text text--darken-2 px-5 pt-6"
    >
      {{ $t("VENDEUR") }}
    </p>
    <!--    <router-link to="/offers" class="dashboard-nav-link px-4">
      <div class="d-flex align-center">
        <img v-svg-inline class="icon mr-2" src="@/assets/images/icons/box.svg" alt="" />
        <span>{{ $t("Offres") }}</span>
      </div>
&lt;!&ndash;      <p class="mb-0">5</p>&ndash;&gt;
    </router-link>-->
    <router-link
      v-if="user && (user.is_seller || user.is_pro_seller)"
      to="/seller/packages"
      class="dashboard-nav-link px-4"
    >
      <div class="d-flex align-center">
        <img
          v-svg-inline
          class="icon mr-2"
          src="@/assets/images/icons/bag.svg"
          alt=""
        >
        <span>{{ $t("Colis à expédier") }}</span>
      </div>
      <!--      <p class="mb-0">5</p>-->
    </router-link>
    <router-link
      v-if="user && (user.is_seller || user.is_pro_seller)"
      to="/products"
      class="dashboard-nav-link px-4"
    >
      <div class="d-flex align-center">
        <img
          v-svg-inline
          class="icon mr-2"
          src="@/assets/images/icons/food.svg"
          alt=""
        >
        <span>{{ $t("Produits") }}</span>
      </div>
      <!--      <p class="mb-0">5</p>-->
    </router-link>
    <router-link
      v-if="user && user.is_pro_seller"
      to="/stock-management"
      class="dashboard-nav-link px-4"
    >
      <div class="d-flex align-center">
        <img
          v-svg-inline
          class="icon mr-2"
          src="@/assets/images/icons/optimization.svg"
          alt=""
        >
        <span>{{ $t("Management de stock") }}</span>
      </div>
      <!--      <p class="mb-0">5</p>-->
    </router-link>
    <router-link
      v-if="user && user.is_pro_seller"
      to="/stock-logs"
      class="dashboard-nav-link px-4"
    >
      <div class="d-flex align-center">
        <img
          v-svg-inline
          class="icon mr-2"
          src="@/assets/images/icons/board.svg"
          alt=""
        >
        <span>{{ $t("Journal de stock") }}</span>
      </div>
      <!--      <p class="mb-0">5</p>-->
    </router-link>
    <router-link
      v-if="user && (user.is_seller || user.is_pro_seller)"
      to="/promotions"
      class="dashboard-nav-link px-4"
    >
      <div class="d-flex align-center">
        <img
          v-svg-inline
          class="icon mr-2"
          src="@/assets/images/icons/announcement.svg"
          alt=""
        >
        <span>{{ $t("Promotions") }}</span>
      </div>
      <!--      <p class="mb-0">5</p>-->
    </router-link>
    <router-link
      v-if="user && user.is_seller"
      to="/transporters"
      class="dashboard-nav-link px-4"
    >
      <div class="d-flex align-center">
        <img
          v-svg-inline
          class="icon mr-2"
          src="@/assets/images/icons/delivery-truck.svg"
          alt=""
        >
        <span>{{ $t("Transporteurs") }}</span>
      </div>
      <!--      <p class="mb-0">5</p>-->
    </router-link>
    <router-link
      v-if="user && (user.is_seller || user.is_pro_seller)"
      to="/wallet"
      class="dashboard-nav-link px-4"
    >
      <div class="d-flex align-center">
        <img
          v-svg-inline
          class="icon mr-2"
          src="@/assets/images/icons/credit-card.svg"
          alt=""
        >
        <span>{{ $t("Revenu") }}</span>
      </div>
      <!--      <p class="mb-0">4</p>-->
    </router-link>
    <router-link
      v-if="user && (user.is_seller || user.is_pro_seller)"
      to="/reviews"
      class="dashboard-nav-link px-4"
    >
      <div class="d-flex align-center">
        <img
          v-svg-inline
          class="icon mr-2"
          src="@/assets/images/icons/star.svg"
          alt=""
        >
        <span>{{ $t("Avis") }}</span>
      </div>
      <!--      <p class="mb-0">5</p>-->
    </router-link>
    <p class="text-sm grey--text text--darken-2 px-5 pt-2" />
    <v-list-item
      dense
      class="dashboard-nav-link px-4"
      @click="$store.commit('logout')"
    >
      <div class="d-flex align-center">
        <img
          v-svg-inline
          class="icon mr-2"
          src="@/assets/images/icons/close-red.svg"
          alt=""
        >
        <span class="red--text">{{ $t("Déconnexion") }}</span>
      </div>
      <!--      <p class="mb-0">5</p>-->
    </v-list-item>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'

  export default {
    computed: {
      ...mapGetters(['getCartProducts', 'unreadNotifications']),
      ...mapState({
        user: 'user',
        login: 'login',
        displayNotification: 'display_notification',
      }),
    },
  }
</script>

<style lang="scss">
    .dashboard-nav-link {
        color: rgb(125, 135, 156) !important;
        text-decoration: none;
        align-items: center;
        display: flex;
        justify-content: space-between;
        border-left: 4px solid #fff;
        margin-bottom: 20px;
        svg {
            width: 20px;
        }
        &.router-link-exact-active {
            color: #D23F57 !important;
            border-left: 4px solid #D23F57;
        }
        &:hover {
            color: #D23F57 !important;
            border-left: 4px solid #D23F57;
        }
    }
</style>
